import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Services <strong className="purple">I Offer </strong>
        </h1>
        <p style={{ color: "white" }}>
        You can choose the service you need from these and get in touch with me.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="AI WEBSITE"
              description="I create AI web applications using React, Tailwind CSS, JavaScript, nodejs and other powerful technologies.

              Utilizing OpenAI and ChatGPT: I build custom AI web applications with the power of React, Tailwind CSS, JavaScript, and more.
              Model Fine-Tuning and Customization: I refine and customize existing models to suit your specific needs.
              AI Model Creation and API Development: I create new AI models and develop the necessary APIs.
              Full-Scale AI App Setup: I set up full-scale AI applications with advanced features, OpenAI integration, and support.
              I provide tailored and robust AI solutions for my clients. How can I assist you? Feel free to get in touch!"
              ghLink="https://github.com/soumyajit4419/Chatify"
              instagramLink="https://www.instagram.com/businessaipro/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="MACHINE LEARNING"
              description="In today's rapidly changing business world, gaining a competitive advantage and making data-driven decisions are of critical importance for both businesses and individuals. It is at this juncture that artificial intelligence technologies such as machine learning and deep learning come into play, revolutionizing business processes.

              Based on historical data, I assist in finding potential customers, customer segmentation, store sales, finance, marketing, advertising, and forecasting the future in sectors such as. I use powerful languages like Python and R while accomplishing these tasks."
              ghLink="https://github.com/soumyajit4419/Bits-0f-C0de"
              instagramLink="https://www.instagram.com/businessaipro/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="DATA ANALYTICS AND VISULATION"
              description="In today's digital age, data consists of billions of bytes and holds great potential in the business world. When collected and analyzed correctly, data becomes a golden resource for industries, providing a competitive advantage. Data analysis enables the transformation of collected data into meaningful insights. However, for these insights to be conveyed and understood by a broader audience, visualization plays a crucial role. I assist in making complex data understandable and effective by using graphics, tables, and interactive visual tools to communicate and convey information.While providing this service, I utilize powerful tools and software such as Python, Tableau, Power BI, and R."
              ghLink="https://github.com/soumyajit4419/Editor.io"
              instagramLink="https://www.instagram.com/businessaipro/"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="AI CHATBOTS"
              description="Businesses have begun to use AI chatbots to gain a competitive advantage in various areas, from customer service to marketing. I train chatbots tailored to your business needs, using Chat-GPT and ManyChat. Here are some of the advantages:

              Customer Service: AI chatbots enhance customer satisfaction by providing 24/7 customer service. They can quickly respond to inquiries, expedite processes, and offer customers a better experience.
              
              Marketing and Sales: Chatbots assist in promoting your products or services by interacting with potential customers. They also enable the development of improved marketing strategies by analyzing customer demands and trends.
              
              In today's digital age, the use of AI chatbots is a crucial step for businesses seeking to excel in the ever-evolving landscape of customer service and marketing."
              ghLink="https://github.com/soumyajit4419/Plant_AI"
              instagramLink="https://www.instagram.com/businessaipro/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="SAAS and E-COMMERCE"
              description="If you want to quickly establish your business, promote your products or services to a broader audience, and reach customers, websites are an indispensable part of your journey. I assist in establishing your business by developing SaaS websites. By creating standard and promotional websites, I ensure that your business and brand are represented online. I also help businesses and individuals kickstart their ventures and begin online sales by developing e-commerce websites.While offering my service, I utilize cutting-edge tools and software such as JavaScript, React, Redux, TailwindCSS, MongoDB. Additionally, if you prefer, I can quickly set up your business using WordPress."
              ghLink="https://github.com/soumyajit4419/AI_For_Social_Good"
              instagramLink = "https://www.instagram.com/businessaipro/"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="AI ART"
              description="AI art represents the inclusion of algorithms and machines in creative processes. In this creative journey, I also strive to provide the best services of AI art by using powerful WebUI tools like Stable Diffusion, Automatic1111, MidJourney. I can create high-quality, customized content just the way you envision it. Whether you're looking for realistic, anime, or animation-style content, I can tailor it to your preferences."
              ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              instagramLink = "https://www.instagram.com/businessaipro/"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default projects;
