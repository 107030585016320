import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Behlul Uluada </span>
            from <span className="purple"> Türkiye (Turkey).</span>
            <br />  I hold a bachelor's degree in Mathematics and Computer Science, 
            an associate degree in Web Design and Coding, and an associate degree in Computer Programming.
            I am currently working in a private company specializing in artificial intelligence and machine learning.
            <br />
            <br>
            </br>


            With my knowledge and experience, I adapt to the rapidly changing dynamics of the tech world, tackling problems with creative and effective solutions. My job is not just about coding;
             it's also about exploring and developing technologies that shape the future.
            
            <br />
            <br />
            As a passionate, ambitious, and lifelong learner, I am determined to do my best to seize the opportunities that technology offers in the future. With my love for technology and expertise,
             I am here to provide innovative solutions and make the world a smarter place.
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> I like to watching sports competitions
            </li>
            <li className="about-activity">
              <ImPointRight /> I like to share things with people
            </li>
            <li className="about-activity">
              <ImPointRight /> I love to technology, nature and music
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            
          </p>
          <footer className="blockquote-footer"></footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
